import { DateTime } from "luxon";
import { ReactElement, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { 
  actions, Meeting, MeetingFilter, MeetingPriority, MeetingStatus, MeetingType, RootState, SelectedSlots, 
  ThunkDispatchType
} from "../../../store";
import { trackEventWithExtra } from "../../../utils/appAnalyticsUtils";
import { 
  EVENT_TYPE, MEETING_PRIORITY_LABELS, MEETING_STATUS_LABELS, PAGE_URL, PROVIDER_CONFERENCE_TYPES 
} from '../../../constants';
import MeetingLog, { FilterSortState, MeetingInfo } from './MeetingLog';
import { DuplicateMeetingSubmitProps } from "../../../components/Schedule/DuplicateMeetingModal/DuplicateMeetingModal";
import { useMountEffect } from "../../../utils/hooks";
import { transformMuiDataGridFilterSortToApiModel } from "../../../utils/dataUtils";
import { uniqBy } from "lodash-es";
import { NewMeetingSubmitProps } from "../NewMeetingModal/NewMeetingModal";
import { transformMarkupToSimpleTemplate, transformMarkupToText } from "@CabComponents/CabTextTokenInput";
import { getLocalTimeZoneName } from "../../../utils/scheduleUtils";
import { useNavigate } from "react-router";
import {
  selectMeetingLogColumnHide, selectMeetingLogColumnSort, selectMeetingLogFilter,
} from "../../../store/cabUI/selectors";
import { GridColumnVisibilityModel, GridFilterModel, GridSortModel } from "@mui/x-data-grid-pro";


const MeetingLogContainer = ({ polls = false, reusable = false }: {
  polls?: boolean; reusable?: boolean
}): ReactElement => {
  const meetingType = polls ? MeetingType.POLL : reusable ? MeetingType.REUSABLE : MeetingType.ONE_OFF;

  const leaders = useSelector((state: RootState) => state.leaders);
  const meetingSlots = useSelector((state: RootState) => state.schedule.meetingSlots);
  const calendarsLoaded = useSelector((state: RootState) => state.schedule.calendarsLoaded);
  const meetingsLoaded = useSelector((state: RootState) => state.schedule.meetingsLoaded);
  const allMeetings = useSelector((state: RootState) => state.schedule.meetings);
  const dataFilter = useSelector((state: RootState) => selectMeetingLogFilter(state, meetingType));
  const columnSort = useSelector((state: RootState) => selectMeetingLogColumnSort(state, meetingType));
  const columnHide = useSelector((state: RootState) => selectMeetingLogColumnHide(state, meetingType));
  const dispatch = useDispatch<ThunkDispatchType>();
  const navigate = useNavigate();

  const meetings: MeetingInfo[] = useMemo(() => (
    Object.values(allMeetings)
      .filter(meeting => (
        polls === !!meeting.is_poll
        && ((reusable === !!meeting.is_reusable) || (reusable && meeting.template_parent))
      ))
      .sort((a, b) => DateTime.fromISO(b.create_date).valueOf() - DateTime.fromISO(a.create_date).valueOf())
      .map(mtg => {
        const mutatedParticipants: {
          [id: string]: { 
            id: number;
            name: string;
            email: string | null;
            emailHash: string;
            selectedSlots: SelectedSlots;
            first_response_date: string | null;
          }
        } = {};

        // Get all participants, even if they have no selections
        Object.values(mtg.participants || {}).forEach(participant => {
          mutatedParticipants[participant.email_hash] = {
            id: participant.id,
            emailHash: participant.email_hash,
            name: participant.name,
            email: participant.email,
            selectedSlots: [],
            first_response_date: participant.first_response_date,
          };
        });

        const slots = uniqBy(Object.values(mtg.poll_selections || {}), selection => (
          `${selection.start_date}-${selection.end_date}`
        )) || [];

        Object.values(mtg.poll_selections || {}).forEach(selection => {
          const participants = mtg.participants || {};
          const participant = participants ? participants[selection.participant] : null;

          if (participant) {
            mutatedParticipants[participant.email_hash]?.selectedSlots.push({
              id: selection.id,
              start: DateTime.fromISO(selection.start_date),
              end: DateTime.fromISO(selection.end_date),
              priority: selection.priority,
            });
          }
        });

        const locations = mtg.conference_provider
          ? [Object.values(PROVIDER_CONFERENCE_TYPES)
            .flatMap(c => c)
            .find(c => c.id === mtg.conference_provider)
            ?.label || '']
          : mtg.locations;

        return {
          ...mtg,
          expectedParticipants: mtg.num_expected_participants || 0,
          slots: slots.map(slot => ({
            start: DateTime.fromISO(slot.start_date),
            end: DateTime.fromISO(slot.end_date),
            priority: slot.priority,
          })),
          participantsInfo: Object.values(mutatedParticipants),
          locations: mtg.status === MeetingStatus.SCHEDULED && mtg.locations_booked ? mtg.locations_booked : locations,
        };
      })
  ), [allMeetings, polls, reusable]);

  const columnVisibility = useMemo(() => {
    // Need to invert columnHide for columnVisibility
    return Object.fromEntries(Object.entries(columnHide).map(([key, val]) => [key, !val]));
  }, [columnHide]);

  const pollMeetings = useCallback(() => {
    let stop = () => {
      return;
    };
    
    (async () => {
      stop = await dispatch(actions.schedule.startPollingMeetings({
        status: MeetingStatus.PENDING, is_poll: polls, is_reusable: reusable, 
      }));
    })();
    return () => stop();
  }, [dispatch, polls, reusable]);

  useMountEffect(pollMeetings);

  useMountEffect(() => {
    if (!calendarsLoaded) {
      dispatch(actions.schedule.fetchRemoteCalendars());
    }
    dispatch(actions.schedule.fetchZoomSettings());
  });

  const handleFetchMeetings = useCallback((
    unscheduled: boolean, completed: boolean, checkMeetingIds: number[], templateParentId?: number, page?: number,
    filterSortState?: FilterSortState,
  ) => {
    let mtgType: MeetingType | undefined = undefined;
    const query = filterSortState ? transformMuiDataGridFilterSortToApiModel<Partial<MeetingFilter>>(
      filterSortState
    ) : {} as Partial<MeetingFilter>;

    // force query to have reusable filter if reusable flag is given
    if (reusable && !templateParentId) {
      mtgType = MeetingType.REUSABLE;
    }

    if (polls) {
      mtgType = MeetingType.POLL;
    }

    return dispatch(actions.schedule.fetchMeetings({
      // no status filtering if templateParentId is given
      query: {
        ...( 
          templateParentId ? 
            {template_parent_id: templateParentId } : (unscheduled 
              ? {status: MeetingStatus.PENDING} 
              : {status__in: [
                MeetingStatus.SCHEDULED, MeetingStatus.NO_RESPONSE, MeetingStatus.POSTPONED, MeetingStatus.CANCELLED
              ]}
            )
        ),
        is_reusable: mtgType === MeetingType.REUSABLE,
        is_poll: mtgType === MeetingType.POLL,
        ...query
      },
      checkMeetingIds, 
      page,
      skipFetchTimeUpdate: true,
    }));
  }, [dispatch, polls, reusable]);

  const handleStatusChange = useCallback(async (meeting: Meeting, statusId: number) => {
    let date_scheduled = null;
    if (statusId !== 1) {
      date_scheduled = DateTime.utc().toString();
    }
    if (meeting.status !== statusId) {
      await dispatch(actions.schedule.updateMeeting({ id: meeting.id, date_scheduled, status: statusId }, []));
      trackEventWithExtra({
        eventName: EVENT_TYPE.SCHEDULING_CHANGE_STATUS,
        extra: { status: MEETING_STATUS_LABELS[statusId] }
      });
    }
  }, [dispatch]);

  const handlePriorityChange = useCallback(async (meeting: Meeting, priorityId: number) => {
    if (meeting.priority !== priorityId) {
      await dispatch(actions.schedule.updateMeeting({ id: meeting.id, priority: priorityId }, []));
      trackEventWithExtra({
        eventName: EVENT_TYPE.SCHEDULING_CHANGE_PRIORITY,
        extra: { priority: MEETING_PRIORITY_LABELS[priorityId] }
      });
    }
  }, [dispatch]);


  const handleScheduleDateChange = useCallback(async (meeting: Meeting, dateScheduled: string | null) => {
    if (meeting.date_scheduled !== dateScheduled) {
      await dispatch(actions.schedule.updateMeeting({ 
        id: meeting.id,
        selected_scheduled_time: dateScheduled,
        status: MeetingStatus.SCHEDULED
      }, []));
      trackEventWithExtra({
        eventName: EVENT_TYPE.SCHEDULING_CHANGE_DATE_SCHEDULED,
        extra:{}
      });
    }
  }, [dispatch]);

  const handleNotesChange = useCallback(async (meeting: Meeting, notes: string) => {
    if (meeting.notes !== notes) {
      return dispatch(actions.schedule.updateMeeting({ id: meeting.id, notes: notes || null }, []));
    }
  }, [dispatch]);

  const handleNewMeeting = useCallback(async (data: NewMeetingSubmitProps) => {
    const showTokenInputs = !polls;

    dispatch(actions.schedule.createMeeting({
      title: showTokenInputs ? transformMarkupToSimpleTemplate(data.title) : data.title || '',
      title_booked: null,
      internal_label: reusable
        ? (data.internal_label || transformMarkupToText(data.title))
        : undefined,
      description: showTokenInputs ? transformMarkupToSimpleTemplate(data.description) : data.description,
      description_booked: null,
      notes: data.notes || undefined,
      duration_minutes: data.duration_minutes || 30,
      num_expected_participants: data.num_expected_participants,
      executive_hold: false,
      hold_calendars: [],
      leaders: data.leaders || [],
      create_date: '',
      date_scheduled: null,
      event_start_time: null,
      selected_scheduled_time: null,
      event_id: null,
      calendar_tz: getLocalTimeZoneName(),
      secondary_tz: [],
      copy_tz: getLocalTimeZoneName(),
      secondary_copy_tz: null,
      status: data.status || 1,
      prevent_conflict: true,
      errors: [],
      error_count: 0,
      show_voter_names: true,
      allow_add_participants: true,
      rooms: [],
      location_presets: [],
      questions: {},
      auto_merge_slots: true,
      enable_public_attendee_list: false,
      allow_reschedule_cancel: false,
      recurring_times: null,
      priority: MeetingPriority.MEDIUM,
      start_delay_minutes: 60,
      files: [],
      is_reusable: reusable,
      is_poll: polls,
      buffer_start_minutes: 0,
      buffer_end_minutes: 0,
      send_participant_reminders: false,
      participant_reminder_minutes: 24 * 60,
    }, [], []));
  }, [dispatch, polls, reusable]);

  const handleEditMeeting = useCallback((meetingId: number): void => {
    navigate(`${PAGE_URL.SCHEDULE}/${meetingId}/`);
  }, [navigate]);

  const handleSeeResults = useCallback((meetingId: number): void => {
    navigate(`${PAGE_URL.POLL_RESULTS }/${meetingId}/`);
  }, [navigate]);

  const handleDeleteMeeting = useCallback(async (meeting: Meeting) => {
    await dispatch(actions.schedule.deleteMeeting(meeting));
  }, [dispatch]);

  const handleFetchMeetingSlots = useCallback(async (unscheduled: boolean) => {
    await dispatch(actions.schedule.fetchMeetingSlots(unscheduled));
  }, [dispatch]);

  const handleDuplicateMeeting = useCallback(async (meetingId: Meeting['id'], data: DuplicateMeetingSubmitProps) => {
    return dispatch(actions.schedule.duplicateMeeting(meetingId, data));
  }, [dispatch]);

  const handleSetTableFilters = (filter: GridFilterModel | undefined) => {
    dispatch(actions.cabUI.setMeetingLogFilter(meetingType, filter || { items: [] }));
  };

  const handleSetColumnSort = (sort: GridSortModel) => {
    dispatch(actions.cabUI.setMeetingLogColumnSort(meetingType, sort));
  };

  const handleSetColumnVisibility = (visibility: GridColumnVisibilityModel) => {
    const hide = Object.fromEntries(Object.entries(visibility)
      .filter(([key, val]) => !val)
      .map(([key, val]) => {
        return [key, true];
      }));
    dispatch(actions.cabUI.setMeetingLogColumnHide(meetingType, hide));
  };

  return (
    <MeetingLog
      polls={polls}
      reusable={reusable}
      meetingsLoaded={meetingsLoaded}
      meetingSlots={meetingSlots}
      meetings={meetings}
      leaders={leaders.leaders}
      onFetchMeetings={handleFetchMeetings}
      onStatusChange={handleStatusChange}
      onPriorityChange={handlePriorityChange}
      onNewMeeting={handleNewMeeting}
      onEditMeeting={handleEditMeeting}
      onSeeReuslts={handleSeeResults}
      onDuplicateMeeting={handleDuplicateMeeting}
      onDeleteMeeting={handleDeleteMeeting}
      onFetchMeetingSlots={handleFetchMeetingSlots}
      onNotesChange={handleNotesChange}
      onScheduleDateChange={handleScheduleDateChange}
      tableFilter={dataFilter}
      tableSort={columnSort}
      columnVisibility={columnVisibility}
      onSetTableFilters={handleSetTableFilters}
      onSetColumnSort={handleSetColumnSort}
      onSetColumnVisibility={handleSetColumnVisibility}
    />
  );
};

export default MeetingLogContainer;
